import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppFavicon, AppIcon } from '../../assets/icons';
import avtar from '../../assets/images/01.png';
import downArrowSVG from '../../assets/images/down-arrow.svg';
import { getLocalStorageItems } from '../../utils/utilities';
import { useAuthenticator } from '@aws-amplify/ui-react';

const Header = () => {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();

  const toggleSideBar = () => {
    document.getElementById('side__bar').classList.toggle('toggled');
  };

  const [userProfile, setUserProfile] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    const { name, profile } = getLocalStorageItems({ getAll: true, keys: ['name', 'email', 'profile'] });
    setUserProfile(profile);
    setName(name);
  }, []);

  const handleUserLogout = () => {
    signOut();
    localStorage.clear();
    navigate('/login', { replace: true });
  };

  return (
    <Fragment>
      <div className="top-header">
        <div className="header-bar d-flex justify-content-between border-bottom">
          <div className="d-flex align-items-center">
            <Link to="/dashboard" className="logo-icon me-3">
              <AppFavicon height="30" className="small" />
              <span className="big">
                <AppIcon height="30" className="logo-light-mode" />
                <AppIcon height="30" className="logo-dark-mode" />
              </span>
            </Link>
            <div id="close-sidebar" className="btn btn-icon btn-soft-light toggle_button" onClick={() => toggleSideBar()}>
              <i className="ti ti-menu-2"></i>
            </div>
            {/* <div className="search-bar p-0 d-none d-md-block ms-2">
                  <div id="search" className="menu-search mb-0">
                    <form
                      role="search"
                      method="get"
                      id="searchform"
                      className="searchform"
                    >
                      <div>
                        <input
                          type="text"
                          className="form-control border rounded"
                          name="s"
                          id="s"
                          placeholder="Search..."
                        />
                        <input type="submit" id="searchsubmit" value="Search" />
                      </div>
                    </form>
                  </div>
                </div> */}
          </div>

          <ul className="list-unstyled mb-0 d-flex align-items-center">
            {/* <li className="list-inline-item mb-0 me-3">
                  <img
                    src={notificationIcon}
                    className="img-fluid"
                    alt=""
                  />
                </li> */}

            <li className="list-inline-item mb-0 ms-1 d-none d-sm-block">
              <div className="dropdown dropdown-primary">
                <button type="button" className="btn dropdown-toggle p-0 dropdown__toggle " data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src={userProfile || avtar} className="avatar avatar-ex-small rounded me-3" alt="" />
                  {name} <img src={downArrowSVG} className="img-fluid ms-3" alt="" />
                </button>
                <div className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 py-3" style={{ minWidth: '200px' }}>
                  <a href=" " className="dropdown-item d-flex align-items-center text-dark pb-3">
                    <img src={userProfile || avtar} className="avatar avatar-md-sm rounded-circle border shadow" alt="" />
                    <div className="flex-1 ms-2">
                      <span className="d-block">{name}</span>
                      {/* <small className="text-muted">UI / UX Designer</small> */}
                    </div>
                  </a>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleUserLogout();
                    }}
                    target="_blank"
                    className="btn btn_logout"
                  >
                    <svg className="me-3" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16.8302 6.08667C17.9837 7.24056 18.7692 8.71057 19.0873 10.3108C19.4054 11.9111 19.2419 13.5698 18.6174 15.0771C17.9929 16.5844 16.9354 17.8728 15.5788 18.7792C14.2221 19.6856 12.6272 20.1693 10.9956 20.1693C9.36403 20.1693 7.76908 19.6856 6.41243 18.7792C5.05579 17.8728 3.99836 16.5844 3.37386 15.0771C2.74936 13.5698 2.58582 11.9111 2.90393 10.3108C3.22204 8.71057 4.00751 7.24056 5.16103 6.08667"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path d="M11 1.83331V11" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>{' '}
                    Logout
                  </button>
                  {/* <a
                        className="dropdown-item text-dark"
                        // href="javascript:void(0)"
                      >
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-home"></i>
                        </span>{" "}
                        Dashboard
                      </a>
                      <a
                        className="dropdown-item text-dark"
                        // href="javascript:void(0)"
                      >
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-settings"></i>
                        </span>{" "}
                        Profile
                      </a>
                      <a
                        className="dropdown-item text-dark"
                        // href="javascript:void(0)"
                      >
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-mail"></i>
                        </span>{" "}
                        {userEmail}
                      </a> */}
                  {/* <div className="dropdown-divider border-top"></div> */}
                  {/* <a
                        className="dropdown-item text-dark"
                        // href="javascript:void(0)"
                      >
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-lock"></i>
                        </span>{" "}
                        Lockscreen
                      </a>
                      <a className="dropdown-item text-dark" href="/index.html">
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-logout"></i>
                        </span>{" "}
                        Logout
                      </a> */}
                </div>
              </div>
            </li>

            <li className="list-inline-item mb-0 ms-1 d-sm-none">
              <div className="dropdown dropdown-primary">
                <button type="button" className="btn dropdown-toggle p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src={userProfile || avtar} className="avatar avatar-ex-small rounded" alt="" />
                </button>
                <div className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 py-3" style={{ minWidth: '200px' }}>
                  <button
                    className="dropdown-item d-flex align-items-center text-dark pb-3"
                    // href="javascript:void(0)"
                  >
                    <img src={userProfile || avtar} className="avatar avatar-md-sm rounded-circle border shadow" alt="" />
                    <div className="flex-1 ms-2">
                      <span className="d-block">{name}</span>
                      {/* <small className="text-muted">UI / UX Designer</small> */}
                    </div>
                  </button>
                  <button
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      handleUserLogout();
                    }}
                    target="_blank"
                    className="btn btn_logout"
                  >
                    <svg className="me-3" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16.8302 6.08667C17.9837 7.24056 18.7692 8.71057 19.0873 10.3108C19.4054 11.9111 19.2419 13.5698 18.6174 15.0771C17.9929 16.5844 16.9354 17.8728 15.5788 18.7792C14.2221 19.6856 12.6272 20.1693 10.9956 20.1693C9.36403 20.1693 7.76908 19.6856 6.41243 18.7792C5.05579 17.8728 3.99836 16.5844 3.37386 15.0771C2.74936 13.5698 2.58582 11.9111 2.90393 10.3108C3.22204 8.71057 4.00751 7.24056 5.16103 6.08667"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path d="M11 1.83331V11" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>{' '}
                    Logout
                  </button>
                  {/* <a
                        className="dropdown-item text-dark"
                        // href="javascript:void(0)"
                      >
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-home"></i>
                        </span>{" "}
                        Dashboard
                      </a>
                      <a
                        className="dropdown-item text-dark"
                        // href="javascript:void(0)"
                      >
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-settings"></i>
                        </span>{" "}
                        Profile
                      </a>
                      <a
                        className="dropdown-item text-dark"
                        // href="javascript:void(0)"
                      >
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-mail"></i>
                        </span>{" "}
                        {userEmail}
                      </a> */}
                  {/* <div className="dropdown-divider border-top"></div>
                      <a
                        className="dropdown-item text-dark"
                        // href="javascript:void(0)"
                      >
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-lock"></i>
                        </span>{" "}
                        Lockscreen
                      </a>
                      <a className="dropdown-item text-dark" href="/index.html">
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-logout"></i>
                        </span>{" "}
                        Logout
                      </a> */}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;

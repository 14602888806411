import { Card, DatePicker, Empty, Spin, Statistic, Table } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ChannelPartnerType, UserTypes } from '../../components/common/userTypes';
import { DashboardByAdmin } from '../../container/actions/dashboard/dashboard.action';
import { resetDashBoard } from '../../container/reducers/slices/dashboardSlice';

import './dashboard.css';
import { formatNumber } from '../../utils/utilities';
const { RangePicker } = DatePicker;

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [userProperty, setUserProperty] = useState([]);

  useEffect(() => {
    dispatch(DashboardByAdmin());

    return () => {
      dispatch(resetDashBoard());
    };
  }, [dispatch]);

  const { data: dashboardData, isLoading } = useSelector((state) => state.dashboardReducer);

  // const handleEmailReview = (data) => {
  //   navigate('/dashboard/junk-emails', { state: data });
  // };

  const tableColumns = [
    {
      title: 'Month-Year',
      dataIndex: 'month',
      key: 'month',
      width: '350px',
      render: (_, record) => {
        let month1 = record.month.split('-');
        const monthString = new Date(2000, month1[0] - 1).toLocaleString('default', { month: 'long' });
        return `${monthString} - ${month1[1]}`;
      },
    },
    {
      title: 'Users',
      dataIndex: 'userCount',
      key: 'userCount',
      width: '350px',
      render: (text) => text || 0,
    },
    {
      title: 'Properties',
      dataIndex: 'propertyCount',
      key: 'propertyCount',
      width: '350px',
      render: (text) => text || 0,
    },
    {
      title: 'Entries',
      dataIndex: 'transactionCount',
      key: 'transactionCount',
      width: '350px',
      render: (text) => text || 0,
    },
  ];
  const pageSize = 4;

  const statsData = [
    {
      title: 'User',
      value: dashboardData?.result?.userData.find((item) => item.user_type === UserTypes.USER)?.count,
      link: '/users',
    },
    {
      title: 'Tax Accountant',
      value: dashboardData?.result?.userData.find((item) => item.user_type === UserTypes.TAXACCOUTANT)?.count,
    },
    {
      title: 'Mortgage Broker',
      value: dashboardData?.result?.userData.find((item) => item.user_type === UserTypes.CHANNEL_PARTNER && item.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER)?.count,
    },
    {
      title: 'Channel Partner',
      value: dashboardData?.result?.userData.find((item) => item.user_type === UserTypes.CHANNEL_PARTNER && item.channel_partner_type !== ChannelPartnerType.MORTGAGE_BROKER)?.count,
      link: '/user/channel-partner',
    },
    {
      title: 'Total Properties',
      value: dashboardData?.result?.propertyData?.count,
    },
    {
      title: 'Total Entries',
      value: dashboardData?.result?.transactionCount,
    },
    {
      title: 'Property Valuation',
      value: formatNumber(dashboardData?.result?.propertyData?.propertyValuation || 0),
      link: '/property-valuation',
    },
    {
      title: 'Transaction Value',
      value: formatNumber(dashboardData?.result?.transactionValue || 0),
    },
    {
      title: 'Android Downloads',
      value: dashboardData?.result?.androidAppDownloads,
    },
    {
      title: 'iOS Downloads',
      value: dashboardData?.result?.iOSAppDownloads,
    },
    {
      title: 'Total Revenue',
      value: formatNumber(dashboardData?.result?.totalRevenue || 0),
    },
  ];

  const cardStatsData = [
    {
      title: 'Depreciation',
      pending: dashboardData?.result?.deprecationData?.pending,
      complete: dashboardData?.result?.deprecationData?.complete,
    },
    {
      title: 'Settlement',
      pending: dashboardData?.result?.settlementData?.pending,
      complete: dashboardData?.result?.settlementData?.complete,
    },
    {
      title: 'Rent Summary',
      pending: dashboardData?.result?.rentData?.pending,
      complete: dashboardData?.result?.rentData?.complete,
    },
    {
      title: 'Property Valuation',
      pending: dashboardData?.result?.marketData?.pending,
      complete: dashboardData?.result?.marketData?.complete,
    },
    {
      title: 'EOFY',
      pending: dashboardData?.result?.eofyData?.pending || 0,
      complete: dashboardData?.result?.eofyData?.complete || 0,
    },
  ];

  const handleDateFilter = (date, dateString) => {};

  return (
    <Spin spinning={isLoading}>
      <div className="row align-items-center">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-5 row-cols-xl-5 g-4 mt-0 dashboardStatsParent card-group">
          {statsData.map((item, i) => {
            return (
              <div className="col">
                <Statistic className="card h-100 p-4 pb-2 pt-3" title={item.title} value={item.value || 0} />
              </div>
            );
          })}
        </div>

        <div className="col-12">
          <div className="text-center d-flex justify-content-between text-sm-start mt-4 mb-sm-0 mb-3 mb-sm-0">
            <div>
              <h5 className="m-0">Users and Properties</h5>
            </div>
            <div>
              <RangePicker allowClear onChange={handleDateFilter} />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table columns={tableColumns} dataSource={dashboardData?.result?.userAndPropertyCount || []} rowKey="month" pagination={{ pageSize }} />
          </div>
        </div>
      </div>

      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5   g-3 dashboard_wrapper">
        {cardStatsData.map((item, i) => {
          return (
            <div className="col">
              <Card title={<h6 className="mb-0">{item.title}</h6>} bordered={false}>
                <div className="d-flex justify-content-between mb-2 align-items-center d-flex">
                  <h6 className="text-muted fw-normal m-0">Pending</h6>
                  <h5 className="card-title fw-bold">{item.pending}</h5>
                </div>
                <div className="d-flex justify-content-between mb-2 align-items-center d-flex">
                  <h6 className="text-muted fw-normal m-0">Completed</h6>
                  <h5 className="card-title fw-bold">{item.complete}</h5>
                </div>
              </Card>
            </div>
          );
        })}
      </div>

      <div className="row dashboard_wrapper">
        <div className="col-md-6 col-12  mt-4">
          <div className="d-flex justify-content-between">
            <h5>User Email</h5>
            <a href=" " onClick={() => navigate('/user/property-investor')}>
              <span className="Route_showmore"> Show more </span>
            </a>
          </div>
          <div className=" card1">
            <ul className="list-group list-group-flush">
              {dashboardData?.result?.newUserData?.length > 0 ? (
                dashboardData?.result?.newUserData?.map((item, i) => {
                  return (
                    <li className="list-group-item">
                      <div className="d-flex align-items-center justify-content-between dashboard_unique_email">
                        <div>
                          <p className="unique_email mb-0">{item?.email}</p>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <li className="list-group-item">
                  <div>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-12  mt-4">
          <div className="d-flex justify-content-between">
            <h5>TaxAccountant Email</h5>
            <a href=" " onClick={() => navigate('/user/tax-accountant', { state: { flag: 'tax' } })}>
              <span className="Route_showmore"> Show more </span>
            </a>
          </div>
          <div className=" card1">
            <ul className="list-group list-group-flush">
              {dashboardData?.result?.newTaxAccountantData?.length > 0 ? (
                dashboardData?.result?.newTaxAccountantData?.map((item, i) => {
                  return (
                    <li className="list-group-item">
                      <div className="d-flex align-items-center justify-content-between dashboard_unique_email">
                        <div>
                          <p className="unique_email mb-0">{item?.email}</p>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <li className="list-group-item">
                  <div>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>

      {/* <div className="row mt-4 dashboard_wrapper">
          <div className="col-12">
            <h5>Junk Emails</h5>
            <div className="card">
              <ul className="list-group list-group-flush">
                {dashData?.diverseMailData?.length > 0 ? (
                  dashData?.diverseMailData?.map((item, i) => {
                    return (
                      <Fragment>
                        <li className="list-group-item">
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <p className="unique_email m-0">{item?.email}</p>
                            </div>
                            <div className="depreciation_dashboard" onClick={() => handleEmailReview(item) }>
                              <p className="m-0">Review</p>
                            </div>
                          </div>
                        </li>
                      </Fragment>
                    );
                  })
                ) : (
                  <Fragment>
                    <li className="list-group-item">
                    <div>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                    </li>
                  </Fragment>
                )}
              </ul>
            </div>
          </div>
        </div> */}
    </Spin>
  );
};

export default Dashboard;

import { configureStore } from '@reduxjs/toolkit';
import depreciationByIdSlice from '../reducers/slices/Depreciation/depreciationByIdSlice';
import loginSlice from '../reducers/slices/loginSlice';
import depreciationSlice from '../reducers/slices/Depreciation/depreciationSlice';
import propertyListByUserSlice from '../reducers/slices/Property/propertyListByUserSlice';
import allSettlementsSlice from '../reducers/slices/settlement/allSettlementsSlice';
import getAllValuationSlices from '../reducers/slices/Valuation/allValuationsSlice';
import updatePropertyValuationSlice from '../reducers/slices/Valuation/updatePropertySlice';
import getSettlementByIdSlice from '../reducers/slices/settlement/settlementByIdSlice';
import getCategoryListSlice from '../reducers/slices/settlement/getCategoryListSlice';
import addSettlementSlice from '../reducers/slices/settlement/addSettlementSlice';
import updateDepreciationSlice from '../reducers/slices/Depreciation/updateDepreciationSlice';
import dashboardSlice from '../reducers/slices/dashboardSlice';
import depreciationCompleteSlice from '../reducers/slices/Depreciation/depriciationCompleteSlice';
import settlementCompleteSlice from '../reducers/slices/settlement/getSettlementSlice';
import DeleteSettlementListSlice from '../reducers/slices/settlement/deleteSettlementSlice';
import DeleteDepreciationListSlice from '../reducers/slices/Depreciation/deleteDepreciationByIdSlice';
import checkDepreciationSlice from '../reducers/slices/Depreciation/checkDepreciation.slice';
import settlementPropertySlice from '../reducers/slices/Property/settlementProperty.slice';
import verifyAdminSlice from '../reducers/slices/verifySlice';
import depreciationCompleteByIdSlice from '../reducers/slices/Depreciation/depreciationCompleteByIdSlice';
import updateCompleteDepreciationSlice from '../reducers/slices/Depreciation/updateCompeleteDepreciationSlice';
import updateSettlementSlice from '../reducers/slices/settlement/updateSettlementSlice';
import PendingRentSummarySlice from '../reducers/slices/RentSummary/getsummarySlice';
import CompleteRentSummarySlice from '../reducers/slices/RentSummary/getCompleteSummarySlice';
import getUsersListSlice from '../reducers/slices/Users/getUsersSlice';
import getTaxAccountantListSlice from '../reducers/slices/Users/getTaxaccountantSlice';
import getPropertyListWithOutLoanSlice from '../reducers/slices/RentSummary/getPropertyListWithOutLoan.slice';
import getRentalSummaryByIdSlice from '../reducers/slices/RentSummary/getRentalSummaryById.slice';
import editRecordExpenseSlice from '../reducers/slices/RentSummary/editRecord.slice';
import getRentalSummaryTransectionByIdSlice from '../reducers/slices/RentSummary/getRentsummaryTransectionByIdSlice';
import addRentalSummarySlice from '../reducers/slices/RentSummary/addRentalSummary.slice';
import getChannelPartnerListSlice from '../reducers/slices/ChannelPartner/getChannelPartnerSlice';
import postChannelPartnerData from '../reducers/slices/ChannelPartner/postChannelPartnerData.slice';
import loansData from '../reducers/slices/Loans/getLoansSlice';
import getBankListSlice from '../reducers/slices/Loans/getBankList.slice';
import getUnconfirmedUsersSlice from '../reducers/slices/UnconfirmedUsers/getUnconfirmedUsersSlice';
import NotificationTemplateSlice from '../reducers/slices/Notification/NotificationTemplateSlice';
import NotificationFilterSlice from '../reducers/slices/Notification/NotificationFilterSlice';
import UploadFileSlice from '../reducers/slices/UploadFile/UploadFileSlice';
import NotificationSlice from '../reducers/slices/Notification/NotificationSlice';
import NotificationPreviewSlice from '../reducers/slices/Notification/NotificationPreviewSlice';
import FinancialYearRentSummarySlice from '../reducers/slices/FYRentSummary/FYRentSummarySlice';
import BasiqBankAccountsSlice from '../reducers/slices/BasiqMultipleAccounts/BasiqBankAccountsSlice';
import UserOverviewSlice from '../reducers/slices/UserOverview/UserOverviewSlice';
import UsersCommentsSlice from '../reducers/slices/Users/getUsersCommentsSlice';
import MobileUnverifiedSlice from '../reducers/slices/MobileUnverifiedUsers/getMobileUnverifiedSlice';

import MortgageBrokerSlice from '../reducers/slices/Users/MortgageBrokerSlice';

const store = configureStore({
  reducer: {
    mortgageBrokers: MortgageBrokerSlice,
    userDetails: UserOverviewSlice,
    UsersComments: UsersCommentsSlice,
    basiqBankAccount: BasiqBankAccountsSlice,
    fyRentSummary: FinancialYearRentSummarySlice,
    notification: NotificationSlice,
    notificationPreview: NotificationPreviewSlice,
    uploadFile: UploadFileSlice,
    notificationTemplate: NotificationTemplateSlice,
    notificationFilters: NotificationFilterSlice,
    loginReducer: loginSlice,
    depreciationByIdReducer: depreciationByIdSlice,
    depreciationCompleteByIdReducer: depreciationCompleteByIdSlice,
    depreciationReducer: depreciationSlice,
    propertyListByUserReducer: propertyListByUserSlice,
    allSettlementReducer: allSettlementsSlice,
    getAllValuationReducer: getAllValuationSlices,
    updatePropertyValuationReducer: updatePropertyValuationSlice,
    getSettlementByIdReducer: getSettlementByIdSlice,
    getCategoryListReducer: getCategoryListSlice,
    addSettlementReducer: addSettlementSlice,
    updateSettlement: updateSettlementSlice,
    updateDepreciationReducer: updateDepreciationSlice,
    updateCompeleteDepreciationReducer: updateCompleteDepreciationSlice,
    dashboardReducer: dashboardSlice,
    depreciationCompleteDataReducer: depreciationCompleteSlice,
    settlementCompleteDataReducer: settlementCompleteSlice,
    settlementDeleteReducer: DeleteSettlementListSlice,
    deleteDepreciationByIdReducer: DeleteDepreciationListSlice,
    checkDepreciationReducer: checkDepreciationSlice,
    propertySettlemetDataReducer: settlementPropertySlice,
    verifyAdminReducer: verifyAdminSlice,
    getRentsummarylistReducer: PendingRentSummarySlice,
    getCompleteRentSummaryReducer: CompleteRentSummarySlice,
    getUsersListReducer: getUsersListSlice,
    getTaxAccountantReducer: getTaxAccountantListSlice,
    getPropertyListWithOutLoanSliceReducer: getPropertyListWithOutLoanSlice,
    getRentalSummaryByIdSliceReducer: getRentalSummaryByIdSlice,
    editRecordExpenseSliceReducer: editRecordExpenseSlice,
    getRentalSummaryTransectionByIdSliceReducer: getRentalSummaryTransectionByIdSlice,
    addRentalSummarySliceReducer: addRentalSummarySlice,
    getChannelPartnerListReducer: getChannelPartnerListSlice,
    postChannelPartnerDataReducer: postChannelPartnerData,
    loansListReducer: loansData,
    getBankListReducer: getBankListSlice,
    getUnconfirmedUsersReducer: getUnconfirmedUsersSlice,
    mobileUnverifiedReducer: MobileUnverifiedSlice,
  },
});

export default store;

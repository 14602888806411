import { ChannelPartnerType, PropertyType, UserTypes } from '../components/common/userTypes';

export const getPropertyTypeLabel = (propertyType) => {
  switch (propertyType) {
    case PropertyType.COMMERCIAL_PROPERTY:
      return 'Commercial - Investment';
    case PropertyType.INVESTMENT_PROPERTY:
      return 'Residential - Investment';
    case PropertyType.OWNER_OCCUPIED_PROPERTY:
      return 'Residential - Owner Occupied';
    default:
      return '-';
  }
};

export const getUserTypeLabel = (userType) => {
  switch (userType) {
    case UserTypes.USER:
      return 'Property Investor';
    case UserTypes.TAXACCOUTANT:
      return 'Tax Accountant';
    case UserTypes.SUB_TAXACCOUTANT:
      return 'Sub Tax Accountant';
    case UserTypes.CHANNEL_PARTNER:
      return 'Channel Partner';
    case UserTypes.ADMIN:
      return 'Admin';
    case UserTypes.SUB_CHANNEL_PARTNER:
      return 'Sub Mortgage Broker';
    case UserTypes.ADMIN_SUB_TAXACCOUNTANT:
      return 'Admin Sub Tax Accountant';
    case ChannelPartnerType.MORTGAGE_BROKER:
      return 'Mortgage Broker';
    default:
      return '-';
  }
};

import dayjs from 'dayjs';
import { ChannelPartnerType, UserTypes } from '../components/common/userTypes';

export const ChannelPartnerTypeOption = [
  {
    value: ChannelPartnerType.CONVEYANCER,
    label: 'Conveyancer',
  },
  {
    value: ChannelPartnerType.RENTAL_PROPERTY_MANAGER,
    label: 'Rental Property Manager',
  },
  {
    value: ChannelPartnerType.BUYERS_AGENT,
    label: 'Buyers Agent',
  },
  {
    value: ChannelPartnerType.OTHERS,
    label: 'Others',
  },
];

export const getFinancialYearRanges = (years) => {
  return years.map((year) => {
    return {
      ...year,
      start: dayjs(`${year.year}-07-01`),
      end: dayjs(`${year.year + 1}-06-30`),
    };
  });
};

const getAustralianFinancialYear = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are 0-based in JavaScript

  if (month >= 7) {
    return `${year % 100}-${(year + 1) % 100}`;
  } else {
    return `${(year - 1) % 100}-${year % 100}`;
  }
};

export const getYearList = () => {
  const start_date = 2022;
  let currentYear = dayjs().year();
  const currentMonth = dayjs().month(); // January is 0

  currentYear = currentMonth >= 6 ? currentYear : currentYear - 1;

  let dataOptions = [];

  for (let index = currentYear; index >= start_date; index--) {
    dataOptions.push({
      label: `FY ${index.toString().slice(-2)}-${(index + 1).toString().slice(-2)}`,
      value: index,
      key: index,
    });
  }
  return dataOptions;
};

export const getErrorMessageForLockedYear = (transaction_date, role, user_id) => {
  const date = new Date(transaction_date);
  const fiscalYear = getAustralianFinancialYear(date);
  if (role === UserTypes.TAXACCOUTANT || role === UserTypes.SUB_TAXACCOUTANT) {
    return `Unable to add/edit/delete! Reports (FY ${fiscalYear}) are locked by your Tax Accountant`;
  } else if (role === UserTypes.USER) {
    return `Unable to add/edit/delete! Reports (FY ${fiscalYear}) are locked by the Property Investor`;
  }
};

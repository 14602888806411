import { getRequest } from "../../../utils/apiUtils";
import { dashboardSuccess, dashboardFailure } from "../../reducers/slices/dashboardSlice";

export const DashboardByAdmin = () => {
  return async (dispatch) => {
    const { result, error } = await getRequest(`/admin/dashboard`);

    if (!error) {
      return dispatch(dashboardSuccess(result));
    }
    return dispatch(dashboardFailure(result));
  };
};

import { getRequest, putRequest } from '../../../utils/apiUtils';

export const getUserDetails = (id) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(`/admin/user-details/${id}`);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const approveApprovalRequest = (id) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await putRequest(`/admin/financial-planner/approve/${id}`);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const rejectApprovalRequest = (id) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await putRequest(`/admin/financial-planner/reject/${id}`);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

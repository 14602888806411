import React from "react";
import { Fragment } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";

const MainLayout = () => {

  const { pathname } = useLocation();

  if(pathname === '/'){
    return <Navigate to="/dashboard" />
  }

  return (
    <Fragment>
      <div className="page-wrapper landrick-theme toggled" id="side__bar">
        <Sidebar />

        <main className="page-content bg-light">
          <Header />

          <div className="container-fluid">
            <div className="layout-specing">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </Fragment>
  );
};

export default MainLayout;

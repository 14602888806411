import { Input, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PencilIcon } from '../../assets/icons';
import { AllSettlements } from '../../container/actions/settlenents/allSettlements.action';

const SettlementPending = () => {
  const dispatch = useDispatch();
  const [settlementData, setSettlementData] = useState([]);
  const [searchText, setSearchText] = useState('');

  const { allSettlementData, loadingAllSettlement } = useSelector((state) => state.allSettlementReducer);

  useEffect(() => {
    const search = JSON.parse(localStorage.getItem('settlementFilter'));
    setSearchText(search || '');
  }, []);

  useEffect(() => {
    dispatch(AllSettlements());
  }, [dispatch]);

  useEffect(() => {
    if (allSettlementData) {
      if (allSettlementData?.result && Object.keys(allSettlementData?.result).length > 0) {
        const allSet = allSettlementData?.result?.map((res) => {
          return { ...res, key: crypto.randomUUID() };
        });
        setSettlementData(allSet);
      } else {
        setSettlementData([]);
      }
    }
  }, [allSettlementData]);

  useEffect(() => {
    if (searchText !== '') {
      const filteredData = allSettlementData?.result?.filter((item) => {
        return item?.user?.email?.toLowerCase().includes(searchText.toLowerCase()) || item?.user?.virtual_email?.toLowerCase().includes(searchText.toLowerCase());
      });

      setSettlementData(filteredData);
    } else {
      setSettlementData(allSettlementData?.result || []);
    }
  }, [searchText, allSettlementData]);

  const tableColumns = [
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => {
        return <>{record?.user?.email}</>;
      },
    },
    {
      title: 'User Unique ID',
      dataIndex: 'virtual_email',
      key: 'virtual_email',
      render: (_, record) => {
        return <>{record?.user?.virtual_email}</>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        return (
          <button className="btn btn-primary px-0 mt-xxl-0 mt-2" onClick={storeData}>
            <Link to={`/property-settlement/pending/${record?.id}`}>
              <PencilIcon className="img-fluid" />
            </Link>
          </button>
        );
      },
    },
  ];

  function storeData() {
    localStorage.setItem('settlementFilter', JSON.stringify(searchText));
  }

  return (
    <div>
      <div className="row align-items-center">
        <div className="row align-items-center mt-2 custom-documentation">
          <div className="col-xl-6 col-md-6 col-sm-6 col-12">
            <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
              <h5 className="m-0">Property Settlement Pending</h5>
            </div>
          </div>
          <div className="col-xl-6 col-md-6 col-sm-6 col-12 text-end custom-search-parent ">
            <Input.Search placeholder="Search by Email, Unique ID" allowClear value={searchText} size="middle" onChange={(e) => setSearchText(e.target.value)} className="custom-search" />
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table columns={tableColumns} dataSource={settlementData} loading={loadingAllSettlement} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettlementPending;

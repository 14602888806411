import { createSlice } from "@reduxjs/toolkit";
import { onFailure, onSuccess } from "../../common";

const initialState = { data: [], error: false, isLoading: true };

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    dashboardSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    dashboardFailure: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.error = true;
    },
    junkEmailSuccess: (state, action) => {
      onSuccess(state, action);
    },
    junkEmailFailure: (state, action) => {
      onFailure(state, action);
    },
    resetDashBoard: () => initialState
  },
});

export const { dashboardSuccess, dashboardFailure, junkEmailSuccess, junkEmailFailure, resetDashBoard } = dashboardSlice.actions;

export default dashboardSlice.reducer;

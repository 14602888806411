import { Button, DatePicker, Flex, Form, Input, Spin, Table, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { PencilIcon } from '../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { GetPendingSummaryList, RegenerateOCR } from '../../container/actions/RentSummary/getsummary.action';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { CheckCircleOutlined, CloseCircleOutlined, RedoOutlined, ReloadOutlined, SyncOutlined } from '@ant-design/icons';
import { OCRStatus } from '../../components/common/userTypes';
import { debounce } from 'lodash';

const RentPending = () => {
  const [summaryData, setSummaryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    searchSlug: '',
  });
  const [inputValue, setInputValue] = useState('');
  const [initialRender, setInitialRender] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    const stored = JSON.parse(localStorage.getItem('rentSummaryFilter'));
    if (stored && initialRender) {
      filterForm.setFieldsValue({
        Search: stored?.searchSlug || '',
        startDate: stored?.startDate ? dayjs(stored.startDate) : null,
        endDate: stored?.endDate ? dayjs(stored.endDate) : null,
      });
      await dispatch(
        GetPendingSummaryList({
          startDate: stored?.startDate || '',
          endDate: stored?.endDate || '',
          searchSlug: stored?.searchSlug || '',
        }),
      ).then((res) => {
        if (res?.payload?.success) {
          setSummaryData(res.payload.result);
        }
        setLoading(false);
      });
    } else {
      const formValues = filterForm.getFieldsValue();
      const { startDate, endDate, Search } = formValues;
      await dispatch(
        GetPendingSummaryList({
          startDate: startDate || '',
          endDate: endDate || '',
          searchSlug: Search || '',
        }),
      ).then((res) => {
        if (res?.payload?.success) {
          setSummaryData(res.payload.result);
        }
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, filterForm, initialRender, filter]);

  const SearchSlug = debounce((e) => {
    setInitialRender(false);
    setInputValue(e.target.value);
    const values = e.target.value.trim();
    setFilter({ ...filter, searchSlug: values });
  }, 300);

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter({ startDate: '', endDate: '', searchSlug: '' });
  };

  const handleRegenerateOCR = (id) => async () => {
    setLoading(true);
    try {
      const res = await dispatch(RegenerateOCR(id));
      console.log('handleRegenerateOCR ~ res:', res);
      if (res.success) {
        notification.success({
          message: 'Success',
          description: 'Request to regenerate OCR has been sent successfully',
        });
        fetchData();
      } else {
        notification.error({
          message: 'Failure',
          description: 'Error in regenerating OCR',
        });
      }
    } catch (err) {
      notification.error({
        message: 'Failure',
        description: err.message || 'Error in regenerating OCR',
      });
    }

    setLoading(false);
  };

  const startDateChange = (value) => {
    setInitialRender(false);
    if (value && dayjs(value).isValid && dayjs(filter.endDate) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greater then End Date.',
      });
      filterForm.setFieldsValue({ startDate: '' });
      return;
    }
    setFilter({ ...filter, startDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const endDateChange = (value) => {
    setInitialRender(false);
    if (value && dayjs(value).isValid && dayjs(filter.startDate) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greater then End Date.',
      });
      filterForm.setFieldsValue({ endDate: '' });
      return;
    }
    setFilter({ ...filter, endDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const RentPendingColumn = [
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => {
        return record?.user?.email ? record.user.email : '';
      },
    },
    {
      title: 'Date',
      dataIndex: 'month',
      key: 'month',
      // sorter: (a, b) => new Date(dayjs(a?.created_at)) - new Date(dayjs(b?.created_at)),
      render: (_, record) => {
        return <>{dayjs(record?.created_at).format('DD-MM-YYYY')}</>;
      },
    },
    {
      title: 'OCR',
      dataIndex: 'ocr_status',
      key: 'ocr_status',
      render: (text) => {
        return text !== OCRStatus.OCR_REVIEW ? (
          text === OCRStatus.OCR_PENDING ? (
            <Tag color="processing" icon={<SyncOutlined spin />}>
              In-progress
            </Tag>
          ) : (
            <Tag color="error" icon={<CloseCircleOutlined />}>
              Failed
            </Tag>
          )
        ) : (
          <Tag color="success" icon={<CheckCircleOutlined />}>
            Success
          </Tag>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'property',
      key: 'property',
      render: (_, record) => {
        return (
          <Flex gap={'middle'}>
            <Button
              // className="btn btn-primary px-2 mt-xxl-0 mt-2"
              type="primary"
              onClick={() => {
                handleClickPending(record);
              }}
              icon={<PencilIcon className="img-fluid" />}
            />
            {record.ocr_status !== OCRStatus.OCR_REVIEW && record.ocr_status !== OCRStatus.OCR_PENDING && (
              <Button
                type="primary"
                title="Regenerate OCR"
                icon={
                  <RedoOutlined
                    style={{
                      paddingTop: 3,
                    }}
                  />
                }
                onClick={handleRegenerateOCR(record.id)}
              />
            )}
          </Flex>
        );
      },
    },
  ];

  const handleClickPending = (record) => {
    localStorage.setItem('rentSummaryFilter', JSON.stringify(filter));
    navigate(`/review-rent/${record.id}`, { state: { UserId: record?.user_id, completed: false } });
  };

  return (
    <>
      <div className="d-flex gap-3 flex-sm-column loans-header-responsive flex-md-row flex-lg-row justify-content-between align-items-center">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Rent Summary Pending </h5>
          </div>
        </div>
        <div className="reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex loans-header-responsive align-items-center gap-2 justify-content-end">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search" className="p-1 m-0">
                    <Input onChange={(e) => SearchSlug(e)} value={inputValue} allowClear style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Button type="primary" icon={<ReloadOutlined />} size="large" className="reset-width-100 d-flex align-items-center" onClick={() => resetFilter()}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="mt-3">
          <div className="row mt-4">
            <div className="col-12">
              <div className="dashboard_list">
                <Table columns={RentPendingColumn} dataSource={summaryData} />
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default RentPending;
